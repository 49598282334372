import { IconPencil } from "@tabler/icons-react";
import { Id } from "../../convex/_generated/dataModel";
import { HydratedLocation, HydratedPlant } from "../../convex/util";
import { LayoutType } from "./LayoutChooser";
import LocationItem from "./LocationItem";

type LocationListProps = {
  variant: LayoutType;
  locations: HydratedLocation[];
  plants: HydratedPlant[];
  onItemClick?: (doc: HydratedLocation) => void;
  onEditClick?: (doc: HydratedLocation) => void;
};

export default function LocationList({
  variant,
  locations,
  plants,
  onItemClick,
  onEditClick,
}: LocationListProps) {
  const plantCount = plants.reduce(
    (acc: Map<Id<"locations">, number>, val: HydratedPlant) => {
      if (val.location) {
        const locationId = val.location.locationId;
        if (acc.has(locationId)) {
          acc.set(locationId, (acc.get(locationId) as number) + 1);
        } else {
          acc.set(locationId, 1);
        }
      }
      return acc;
    },
    new Map<Id<"locations">, number>()
  );
  return (
    <>
      {locations.map((doc) => (
        <LocationItem
          key={doc._id}
          variant={variant}
          doc={doc}
          plantCount={plants.length > 0 ? plantCount.get(doc._id) : undefined}
          onClick={() => onItemClick && onItemClick(doc)}
          actionIcons={onEditClick ? [
            {
              icon: IconPencil,
              onClick: () => onEditClick(doc),
            },
          ] : []}
        />
      ))}
    </>
  );
}

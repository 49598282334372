import { Carousel } from "@mantine/carousel";
import {
  ActionIcon,
  Box,
  Checkbox,
  Group,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";

import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";

import { useConvex, useQuery } from "convex/react";

import { api } from "../../convex/_generated/api";
import { HydratedLocation } from "../../convex/util";

import { IconMapPin, IconPencil } from "@tabler/icons-react";
import { useRef } from "react";
import { formatDate } from "../lib/util";
import DebugTooltip from "./DebugTooltip";
import DetailSectionHeader from "./DetailSectionHeader";
import EntryDetail from "./EntryDetail";
import { openAddEntryModal } from "./EntryDialog";
import EntryList from "./EntryList";
import { useLayoutState } from "./LayoutChooser";
import { openEditLocationModal } from "./LocationDialog";
import LocationItem from "./LocationItem";
import { usePile } from "./NavPile";
import PileBackLink from "./PileBackLink";
import PlantDetail from "./PlantDetail";
import { openAddPlantModal } from "./PlantDialog";
import PlantItem from "./PlantItem";
import { GardenContext } from "./GardenContext";
import { useContext } from "react";
import { writingRoles } from "../../convex/shared_util";
import { usePersistentUIState } from "../hooks/usePersistentUIState";
import LocationText from "./LocationText";
export type LocationDetailProps = {
  location: HydratedLocation;
};

export default function LocationDetail({
  location: propsLocation,
}: LocationDetailProps) {
  const currentGarden = useContext(GardenContext);
  const liveLocation = useQuery(api.gardens.getLocation, {
    locationId: propsLocation._id,
  });
  const location = liveLocation ? liveLocation : propsLocation;

  const [push] = usePile();

  const locations = useQuery(api.gardens.listLocations, {
    gardenId: location.gardenId,
  });
  const sublocations =
    locations &&
    locations.filter((doc) => doc.parentLocationId === location._id);

  const plants = useQuery(api.gardens.listPlants, {
    gardenId: location.gardenId,
    locationId: location._id,
  });

  const [includeImpliedEntries, setIncludeImpliedEntries] = usePersistentUIState<boolean>("location-detail-include-implied-entries", false);
  const entries = useQuery(api.gardens.listLocationEntries, {
    gardenId: location.gardenId,
    locationId: location._id,
    includeImpliedEntries,
  });

  const parentLocation = useQuery(api.gardens.getLocation, location.parentLocationId ? {
    locationId: location.parentLocationId,
  } : "skip");

  const convex = useConvex();

  const wheelGestures = useRef(WheelGesturesPlugin());

  const locationSection = (
    <Stack gap={4}>
      <Title order={3}>Locations</Title>
      <Carousel
        align="start"
        slideSize="50%"
        slideGap={6}
        withControls={false}
        skipSnaps={true}
        plugins={[wheelGestures.current]}
        styles={{ viewport: { overflow: "visible" } }}
      >
        {sublocations?.map((doc, idx) => (
          <Carousel.Slide key={idx}>
            <LocationItem
              variant="cards"
              size="sm"
              doc={doc}
              sizes="50vw"
              onClick={() => push(<LocationDetail location={doc} />, doc.name)}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </Stack>
  );

  const plantsSection = (
    <Stack gap={4}>
      <DetailSectionHeader
        title="Plants"
        onAddClick={currentGarden && writingRoles.includes(currentGarden.role) ? () => openAddPlantModal(convex, location.gardenId) : undefined}
      />

      {plants && plants.length > 0 && (
        <Carousel
          align="start"
          slideSize="50%"
          slideGap={6}
          withControls={false}
          skipSnaps={true}
          plugins={[wheelGestures.current]}
          styles={{ viewport: { overflow: "visible" } }}
        >
          {plants?.map((doc, idx) => (
            <Carousel.Slide key={idx}>
              <PlantItem
                variant="cards"
                size="sm"
                doc={doc}
                sizes="50vw"
                onClick={() => push(<PlantDetail plant={doc} />, doc.name)}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      )}
      {plants && plants.length === 0 && (
        <Text c="dark.1" size="sm">
          None yet!
        </Text>
      )}
    </Stack>
  );

  const [journalLayout, setJournalLayout] = useLayoutState(
    "journal-section",
    "timeline"
  );

  const journalSection = (
    <Stack gap={4}>
      <DetailSectionHeader
        title="Journal"
        layoutOptions={["list", "cards", "timeline"]}
        setLayout={setJournalLayout}
        activeLayout={journalLayout}
        onAddClick={currentGarden && writingRoles.includes(currentGarden.role) ? () =>
          openAddEntryModal(convex, location.gardenId, [
            { type: "location", id: location._id },
          ])
          : undefined}
      />
      <Checkbox
        label="Include entries for plants in this location"
        checked={includeImpliedEntries}
        onChange={(event) =>
          setIncludeImpliedEntries(event.currentTarget.checked)
        }
        size="xs"
        styles={{ label: { paddingLeft: 8 } }}
      />
      <Stack gap="xs">
        <EntryList
          layout={journalLayout}
          entries={(plants && entries) || []}
          onItemClick={(doc) =>
            push(<EntryDetail entry={doc} />, formatDate(doc.date))
          }
        />
      </Stack>
    </Stack>
  );

  return (
    // outer box creates stacking context
    <Box pos="absolute" inset={0} style={{ zIndex: 0 }}>
      <Box
        pos="absolute"
        inset={0}
        p="md"
        bg="white"
        style={{ overflowY: "scroll", overflowX: "clip" }}
      >
        <Stack gap={4}>
          <PileBackLink />
          <Stack gap={0}>
            <Group justify="space-between" align="center" wrap="nowrap">
              <Group align="center" gap={4} wrap="nowrap">
                <DebugTooltip label={location._id}>
                  <Title order={2} lineClamp={1}>
                    {location.name}
                  </Title>
                </DebugTooltip>
                <IconMapPin size={20} />
              </Group>
              {currentGarden && writingRoles.includes(currentGarden.role) && <ActionIcon
                size="sm"
                variant="light"
                onClick={() => openEditLocationModal(convex, location)}
              >
                <IconPencil size={16} />
              </ActionIcon>}
            </Group>
            {parentLocation &&
              <UnstyledButton onClick={parentLocation ? () => push(<LocationDetail location={parentLocation} />) : undefined}>
                <LocationText size="md" locationName={parentLocation.name} />
              </UnstyledButton>}
          </Stack>
        </Stack>
        <Stack mt="md" gap="lg">
          {(sublocations === undefined || sublocations.length > 0) &&
            locationSection}
          {plantsSection}
          {journalSection}
        </Stack>
      </Box>
    </Box>
  );
}

import { IconPencil } from "@tabler/icons-react";
import { HydratedPlant } from "../../convex/util";
import { LayoutType } from "./LayoutChooser";
import PlantItem from "./PlantItem";

type PlantListProps = {
  layout: LayoutType;
  plants: HydratedPlant[];
  onItemClick?: (doc: HydratedPlant) => void;
  onEditClick?: (doc: HydratedPlant) => void;
};

export default function PlantList({
  layout,
  plants,
  onItemClick,
  onEditClick,
}: PlantListProps) {
  return (
    <>
      {plants.map((doc) => (
        <PlantItem
          key={doc._id}
          variant={layout}
          doc={doc}
          onClick={() => onItemClick && onItemClick(doc)}
          actionIcons={onEditClick ? [
            {
              icon: IconPencil,
              onClick: () => onEditClick(doc),
            },
          ] : []}
        />
      ))}
    </>
  );
}

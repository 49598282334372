import { useCallback, useRef, useState } from "react";

import { useMutation, useQuery } from "convex/react";

import { ActionIcon, Button, CopyButton, Group, Stack, Tabs, Text, TextInput } from "@mantine/core";
import {
  useDisclosure,
  useDocumentTitle,
} from "@mantine/hooks";
import { modals, ModalsProvider } from "@mantine/modals";

import { IconCheck, IconCopy, IconMapPin, IconNotebook, IconSeeding } from "@tabler/icons-react";

import { api } from "../../convex/_generated/api";

import AccountModal from "./AccountModal";
import GardenDialog from "./GardenDialog";
import JournalPane from "./JournalPane";
import LocationsPane from "./LocationsPane";
import TopBar from "./TopBar";
import ShareGardenDialog from "./ShareGardenDialog";

import { HydratedGarden } from "../../convex/util";
import { GardenContext } from "./GardenContext";
import NavPile, { NavPileHandle } from "./NavPile";
import PlantsPane from "./PlantsPane";
import PreferencesContext from "./PreferencesContext";
import { usePersistentUIState } from "../hooks/usePersistentUIState";

export type GardenJournalTabs =
  | "home"
  | "locations"
  | "plants"
  | "journal"
  | "today";
// | "calendar";

export default function AuthenticatedApp() {
  const [title, _setTitle] = useState("Plantings");
  const setTitle = useCallback((title: string) => {
    var newTitle = title;
    if (!window.location.host.endsWith("plantings.app")) {
      newTitle = `DEV ${title} DEV`;
    }
    _setTitle(newTitle);
  }, [_setTitle]);
  useDocumentTitle(title);
  const user = useQuery(api.users.currentUser, {});

  const preferences = useQuery(api.users.getPreferences, user ? {} : "skip");
  const updateGarden = useMutation(api.gardens.updateGarden);

  const [currentGarden, setCurrentGarden] = useState<
    HydratedGarden | undefined
  >();

  const [lastTab, setLastTab] = usePersistentUIState<GardenJournalTabs>(
    "app-last-tab",
    "locations"
  );

  const [
    accountModalOpened,
    { open: accountModalOpen, close: accountModalClose },
  ] = useDisclosure(false);

  const piles: { [key in GardenJournalTabs]?: React.RefObject<NavPileHandle> } =
  {
    // today: useRef<NavPileHandle>(null),
    plants: useRef<NavPileHandle>(null),
    locations: useRef<NavPileHandle>(null),
    journal: useRef<NavPileHandle>(null),
  };

  return (
    <PreferencesContext.Provider value={preferences}>
      <GardenContext.Provider value={currentGarden}>
        <ModalsProvider>
          <TopBar
            onAccountClick={accountModalOpen}
            onGardenShare={(garden: HydratedGarden) => {
              modals.open({
                title: "Share Garden",
                children: (
                  <ShareGardenDialog
                    garden={garden}
                    opened={true}
                    onClose={() => modals.closeAll()}
                  />
                ),
              });
            }}
            onSharingClick={() => {
              modals.open({
                title: "Sharing",
                children: <>
                  <Stack>
                    <Text>This garden is publicly shared.</Text>
                    <TextInput value={window.location.href} rightSection={<CopyButton value={window.location.href}>
                      {({ copied, copy }) => (
                        <ActionIcon color="gray" variant="subtle" onClick={copy}>
                          {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                        </ActionIcon>
                      )}
                    </CopyButton>} />
                    <Group justify="flex-end"><Button onClick={() => modals.closeAll()}>Close</Button></Group>
                  </Stack>
                </>
              });
            }}
            onGardenEdit={() => {
              if (currentGarden) {
                modals.open({
                  title: "Edit garden",
                  children: (
                    <GardenDialog
                      confirmLabel="Save"
                      onConfirm={async (values) => {
                        await updateGarden({
                          id: currentGarden._id,
                          name: values.name,
                          coverPhotoId: values.coverPhoto?.storageId || null,
                        });
                        setCurrentGarden({
                          ...currentGarden,
                          ...values,
                        });
                      }}
                      cancelLabel="Cancel"
                      initialValues={{
                        name: currentGarden.name,
                        coverPhoto: currentGarden.coverPhoto,
                      }}
                    />
                  ),
                });
              }
            }}
            onGardenChange={(garden) => {
              setCurrentGarden(garden);
              setTitle(`Plantings: ${garden.name}`);
            }}
          />

          <AccountModal
            opened={accountModalOpened}
            onClose={accountModalClose}
          />

          <Tabs
            inverted
            defaultValue={lastTab}
            onChange={(value) => {
              if (value)
                if (value == lastTab) {
                  piles[value]?.current?.popAll();
                  // document
                  //   .getElementById(`${value}-pane`)
                  //   ?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                } else {
                  setLastTab(value as GardenJournalTabs);
                }
            }}
            styles={{
              root: {
                position: "fixed",
                inset: "0 0 env(safe-area-inset-bottom)",
              },
              list: {
                position: "absolute",
                inset: "auto 0 0",
              },
              tab: {
                flex: 1,
                padding: "var(--mantine-spacing-xs) 0",
              },
              panel: {
                position: "absolute",
                inset: 0,
                marginTop: "56px",
                marginBottom: "70px",
              },
            }}
          >
            {/* <Tabs.Panel value="today">
              <NavPile title="Dummy" ref={piles.today}>
                <Text>Today</Text>
              </NavPile>
            </Tabs.Panel> */}
            <Tabs.Panel value="journal">
              <NavPile title="Journal" ref={piles.journal}>
                <JournalPane />
              </NavPile>
            </Tabs.Panel>
            <Tabs.Panel value="plants">
              <NavPile title="Plants" ref={piles.plants}>
                <PlantsPane />
              </NavPile>
            </Tabs.Panel>
            <Tabs.Panel value="locations">
              <NavPile title="Locations" ref={piles.locations}>
                <LocationsPane />
              </NavPile>
            </Tabs.Panel>
            {/* <Tabs.Panel value="calendar">Calendar</Tabs.Panel> */}

            <Tabs.List grow justify="space-between" bg="gray.1">
              {/* <Tabs.Tab value="today">
                <Stack gap="xs" align="center">
                  <IconSun />
                  <Text>Today</Text>
                </Stack>
              </Tabs.Tab> */}
              <Tabs.Tab value="journal">
                <Stack gap={0} align="center">
                  <IconNotebook />
                  <Text>Journal</Text>
                </Stack>
              </Tabs.Tab>
              <Tabs.Tab value="plants">
                <Stack gap={0} align="center">
                  <IconSeeding />
                  <Text>Plants</Text>
                </Stack>
              </Tabs.Tab>
              <Tabs.Tab value="locations">
                <Stack gap={0} align="center">
                  <IconMapPin />
                  <Text>Locations</Text>
                </Stack>
              </Tabs.Tab>
              {/* <Tabs.Tab value="calendar">
                <Stack gap="xs" align="center">
                  <IconCalendar />
                  <Text>Calendar</Text>
                </Stack>
              </Tabs.Tab> */}
            </Tabs.List>
          </Tabs>
        </ModalsProvider>
      </GardenContext.Provider>
    </PreferencesContext.Provider>
  );
}

import { ActionIcon, Box, Checkbox, Group, Stack, Title, UnstyledButton } from "@mantine/core";

import { useConvex, useQuery } from "convex/react";

import { api } from "../../convex/_generated/api";
import { HydratedPlant } from "../../convex/util";

import { IconPencil, IconSeeding } from "@tabler/icons-react";
import DebugTooltip from "./DebugTooltip";
import DetailSectionHeader from "./DetailSectionHeader";
import EntryDetail from "./EntryDetail";
import { openAddEntryModal } from "./EntryDialog";
import EntryList from "./EntryList";
import { useLayoutState } from "./LayoutChooser";
import LocationText from "./LocationText";
import { usePile } from "./NavPile";
import PileBackLink from "./PileBackLink";
import { openEditPlantModal } from "./PlantDialog";
import SubjectPills from "./SubjectPills";
import { GardenContext } from "./GardenContext";
import { useContext } from "react";
import { writingRoles } from "../../convex/shared_util";
import { usePersistentUIState } from "../hooks/usePersistentUIState";
import LocationDetail from "./LocationDetail";
export type PlantDetailProps = {
  plant: HydratedPlant;
};

export default function PlantDetail({ plant: propsPlant }: PlantDetailProps) {
  const currentGarden = useContext(GardenContext);
  const livePlant = useQuery(api.gardens.getPlant, { plantId: propsPlant._id });
  const plant = livePlant ? livePlant : propsPlant;

  const [push] = usePile();

  const [includeImpliedEntries, setIncludeImpliedEntries] = usePersistentUIState<boolean>("plant-detail-include-implied-entries", false);
  const entries = useQuery(api.gardens.listPlantEntries, {
    gardenId: plant.gardenId,
    plantId: plant._id,
    includeImpliedEntries,
  });

  const descendants = useQuery(api.gardens.listPlantDescendants, {
    gardenId: plant.gardenId,
    plantId: plant._id,
  });

  const location = useQuery(api.gardens.getLocation, plant.location ? {
    locationId: plant.location?.locationId,
  } : "skip");

  const convex = useConvex();

  const [journalLayout, setJournalLayout] = useLayoutState(
    "journal-section",
    "cards"
  );

  const journalSection = (
    <Stack gap={4}>
      <DetailSectionHeader
        title="Journal"
        layoutOptions={["list", "cards", "timeline"]}
        setLayout={setJournalLayout}
        activeLayout={journalLayout}
        onAddClick={currentGarden && writingRoles.includes(currentGarden.role) ? () =>
          openAddEntryModal(convex, plant.gardenId, [
            { type: "plant", id: plant._id },
          ])
          : undefined}
      />
      <Checkbox
        label="Include entries for plant location"
        checked={includeImpliedEntries}
        onChange={(event) =>
          setIncludeImpliedEntries(event.currentTarget.checked)
        }
        size="xs"
        styles={{ label: { paddingLeft: 8 } }}
      />
      <Stack gap="xs">
        <EntryList
          layout={journalLayout}
          entries={entries || []}
          onItemClick={(doc) => push(<EntryDetail entry={doc} />)}
        />
      </Stack>
    </Stack>
  );

  return (
    // outer box creates stacking context
    <Box pos="absolute" inset={0} style={{ zIndex: 0 }}>
      <Box
        pos="absolute"
        inset={0}
        p="md"
        bg="white"
        style={{ overflowY: "scroll", overflowX: "clip" }}
      >
        <Stack gap={4}>
          <PileBackLink />
          <Stack gap={0}>
            <Group justify="space-between" align="center" wrap="nowrap">
              <Group align="center" gap={4} wrap="nowrap">
                <DebugTooltip label={plant._id}>
                  <Title order={2} lineClamp={1}>
                    {plant.name}
                  </Title>
                </DebugTooltip>
                <IconSeeding size={20} />
              </Group>
              {currentGarden && writingRoles.includes(currentGarden.role) && <ActionIcon
                size="sm"
                variant="light"
                onClick={() => openEditPlantModal(convex, plant)}
              >
                <IconPencil size={16} />
              </ActionIcon>}
            </Group>
            <UnstyledButton onClick={location ? () => push(<LocationDetail location={location} />) : undefined}>
              <LocationText size="md" locationName={plant.location?.name} />
            </UnstyledButton>
          </Stack>
        </Stack>
        {descendants && descendants.length > 0 && (
          <Stack mt="md" gap="xs">
            <Title order={3} lineClamp={1}>
              Descendants
            </Title>
            <Group gap="xs">
              <SubjectPills
                subjects={descendants.map((descendant) => ({
                  type: "plant",
                  doc: descendant,
                }))}
                includePlantLocation
                clickable
                size="sm"
              />
            </Group>
          </Stack>
        )}
        <Stack mt="md" gap="lg">
          {journalSection}
        </Stack>
      </Box>
    </Box>
  );
}

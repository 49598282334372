import { ActionIcon, Breadcrumbs } from "@mantine/core";
import {
  IconCarouselHorizontal,
  IconGitCommit,
  IconLayoutGrid,
  IconLayoutList,
  IconListDetails,
  IconSquare,
} from "@tabler/icons-react";
import { usePersistentUIState } from "../hooks/usePersistentUIState";

const icons = {
  list: IconLayoutList,
  cards: IconSquare,
  carousel: IconCarouselHorizontal,
  grid: IconLayoutGrid,
  details: IconListDetails,
  timeline: IconGitCommit,
};
export type LayoutType = keyof typeof icons;

export function useLayoutState(
  key: string,
  defaultLayout: LayoutType
): [LayoutType, (layout: LayoutType) => void] {
  return usePersistentUIState<LayoutType>(`layout-${key}`, defaultLayout);
}

type LayoutChooserProps = {
  options?: LayoutType[];
  activeLayout: LayoutType;
  setLayout: (layout: LayoutType) => void;
};

export default function LayoutChooser({
  options = ["list", "cards"],
  activeLayout,
  setLayout,
}: LayoutChooserProps) {
  return (
    <Breadcrumbs
      styles={{
        separator: { marginInline: 0 },
      }}
    >
      {options.map((layout) => {
        const Icon = icons[layout];
        return (
          <ActionIcon
            key={layout}
            variant="transparent"
            {...(activeLayout != layout && { c: "gray.6" })}
            onClick={() => setLayout(layout)}
          >
            <Icon size={18} />
          </ActionIcon>
        );
      })}
    </Breadcrumbs>
  );
}

import { useLocalStorage } from "@mantine/hooks";
import { useState } from "react";

/**
 * A hook for persisting UI state in localStorage
 * 
 * @param key - Unique key to identify this state in localStorage
 * @param defaultValue - Default value to use if no value is found in localStorage
 * @returns A tuple of [value, setValue] similar to useState
 */
export function usePersistentUIState<T>(
  key: string,
  defaultValue: T
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useLocalStorage<T>({
    key: `ui-state-${key}`,
    defaultValue,
    getInitialValueInEffect: false,
  });

  const [value, setValue] = useState<T>(storedValue);

  const updateValue = (newValue: T) => {
    setStoredValue(newValue);
    setValue(newValue);
  };

  return [value, updateValue];
} 
import { useContext } from "react";

import { useConvex, useQuery } from "convex/react";

import { Box, Group, Title } from "@mantine/core";

import { api } from "../../convex/_generated/api";
import { writingRoles } from "../../convex/shared_util";

import { formatDate } from "../lib/util";
import EntryDetail from "./EntryDetail";
import { openAddEntryModal, openEditEntryModal } from "./EntryDialog";
import EntryList from "./EntryList";
import FastScrollArea from "./FastScrollArea";
import FloatingButton from "./FloatingButton";
import GardenContext from "./GardenContext";
import LayoutChooser, { useLayoutState } from "./LayoutChooser";
import { usePile } from "./NavPile";

export default function JournalPane() {
  const [push] = usePile();
  const currentGarden = useContext(GardenContext);
  const convex = useConvex();

  const entries = useQuery(
    api.gardens.listGardenEntries,
    currentGarden ? { gardenId: currentGarden._id } : "skip"
  );
  const [layout, setLayout] = useLayoutState("journal-pane", "cards");

  if (!currentGarden) {
    return <></>;
  }

  return (
    <Box pos="absolute" inset={0} style={{ zIndex: 0 }}>
      <FastScrollArea pos="absolute" inset={0}>
        <Box pos="relative" inset={0} p="md" pb={writingRoles.includes(currentGarden.role) ? "100px" : "md"}  >
          <Group justify="space-between" align="center" mb="md">
            <Title order={2}>Journal</Title>
            <LayoutChooser
              options={["list", "cards", "timeline"]}
              activeLayout={layout}
              setLayout={setLayout}
            />
          </Group>
          <EntryList
            layout={layout}
            entries={entries || []}
            onItemClick={(doc) =>
              push(<EntryDetail entry={doc} />, formatDate(doc.date))
            }
            onEditClick={currentGarden && writingRoles.includes(currentGarden.role) ? (doc) => openEditEntryModal(convex, doc) : undefined}
          />
        </Box>
      </FastScrollArea>

      {currentGarden && writingRoles.includes(currentGarden.role) && <FloatingButton
        label="Add entry"
        onClick={() => openAddEntryModal(convex, currentGarden._id)}
      />}
    </Box>
  );
}

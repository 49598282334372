import { useContext, useState } from "react";

import { useConvex, useQuery } from "convex/react";

import { Box, Button, Group, Stack, Title, Menu } from "@mantine/core";
import { modals } from "@mantine/modals";

import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";

import { HydratedLocation } from "../../convex/util";
import { writingRoles } from "../../convex/shared_util";

import FastScrollArea from "./FastScrollArea";
import GardenContext from "./GardenContext";
import LayoutChooser, { useLayoutState } from "./LayoutChooser";
import { openAddPlantModal, openEditPlantModal } from "./PlantDialog";
import PlantFilterDialog, {
  PlantFilterSettings,
  plantSortLabels,
} from "./PlantFilterDialog";
import PlantList from "./PlantList";

import FloatingButton from "./FloatingButton";
import { usePile } from "./NavPile";
import PlantDetail from "./PlantDetail";
import { IconFilter, IconSortAscending, IconSortDescending } from "@tabler/icons-react";
import { usePersistentUIState } from "../hooks/usePersistentUIState";

function describeFilter(
  filter: PlantFilterSettings,
  locations?: HydratedLocation[]
) {
  let description = filter.name || filter.locationId ? "Plants" : "All plants";
  if (filter.locationId && locations) {
    const foundLocation = locations.filter(
      (doc) => doc._id == filter.locationId
    );
    const locationName = foundLocation[0].name;
    description += ` in ${locationName.toLowerCase()}`;
  }
  if (filter.name) {
    description += ` matching \u2018${filter.name}\u2019`;
  }

  if (filter.includeGone) {
    description += ", including archived";
  }
  const sortField = plantSortLabels[filter.sortFields[0]].toLowerCase();
  description += `, by ${sortField}`;
  return description;
}

export default function PlantsPane() {
  const [push] = usePile();

  const currentGarden = useContext(GardenContext);
  const locations = useQuery(
    api.gardens.listLocations,
    currentGarden ? { gardenId: currentGarden._id } : "skip"
  );

  const convex = useConvex();

  const [layout, setLayout] = useLayoutState("plants-pane", "list");

  type SortSettings = Pick<
    PlantFilterSettings,
    "sortFields" | "sortDirections"
  >;
  const [defaultSort, setDefaultSort] = usePersistentUIState<SortSettings>(
    "plants-pane-sort",
    {
      sortFields: ["location", "name", "ts"],
      sortDirections: {
        location: "descending",
        name: "descending",
        ts: "descending",
      },
    }
  );

  const filterReset: PlantFilterSettings = {
    name: "",
    locationId: undefined,
    includeGone: false,
    ...defaultSort,
  };
  const [filter, _setFilter] = useState<PlantFilterSettings>(filterReset);
  const setFilter = (filter: PlantFilterSettings) => {
    _setFilter(filter);
    setDefaultSort({
      sortFields: filter.sortFields,
      sortDirections: filter.sortDirections,
    });
  };
  const [filterDescription, setFilterDescription] = useState<string>(
    describeFilter(filter, locations)
  );

  const plants = useQuery(
    api.gardens.listPlants,
    currentGarden
      ? {
        gardenId: currentGarden._id,
        // for better caching, don't use the includeGone flag if it's false
        ...(filter.includeGone ? { includeGone: filter.includeGone } : {}),
      }
      : "skip"
  );

  if (!currentGarden) return <></>;

  type LocationIdOrEmpty = Id<"locations"> | "";
  const locationOrdering: { [key in LocationIdOrEmpty]: number } = locations
    ? locations.reduce(
      (acc, doc, index) => {
        acc[doc._id] = index;
        return acc;
      },
      {} as Record<Id<"locations"> | "", number>
    )
    : ({} as { [key in LocationIdOrEmpty]: number });
  // treat empty location as first in the list
  if (locationOrdering) locationOrdering[""] = -1;

  const filteredPlants = plants
    ? plants.filter((doc) => {
      if (
        filter.locationId &&
        (doc.location == null || doc.location.locationId != filter.locationId)
      )
        return false;
      if (
        filter.name &&
        !doc.name.toLowerCase().includes(filter.name.toLowerCase())
      )
        return false;
      return true;
    })
    : [];

  filteredPlants.sort((a, b) => {
    for (const field of filter.sortFields) {
      const direction = filter.sortDirections[field] == "descending" ? 1 : -1;
      let cmp = 0;
      switch (field) {
        case "name":
          cmp = a.name.localeCompare(b.name);
          break;
        case "location":
          if (locationOrdering) {
            cmp =
              locationOrdering[a.location?.locationId || ""] -
              locationOrdering[b.location?.locationId || ""];
          }
          break;
        case "ts":
          cmp = a._creationTime - b._creationTime;
          break;
      }
      return cmp * direction;
    }
    return 0;
  });

  const onConfirmFilter = (newFilter: PlantFilterSettings) => {
    setFilter(newFilter);
    setFilterDescription(describeFilter(newFilter, locations));
    modals.closeAll();
  };

  const IconSortDirection = filter.sortDirections[filter.sortFields[0]] == "ascending" ? IconSortAscending : IconSortDescending;

  return (
    <Box pos="absolute" inset={0} style={{ zIndex: 0 }}>
      <FastScrollArea pos="absolute" inset={0}>
        <Box pos="relative" inset={0} p="md" pb={writingRoles.includes(currentGarden.role) ? "100px" : "md"} >
          <Stack gap={0}>
            <Group justify="space-between" align="center">
              <Title order={2}>Plants</Title>
              <LayoutChooser activeLayout={layout} setLayout={setLayout} />
            </Group>
            <Group justify="space-between">
              <Menu shadow="md" position="bottom-start">
                <Menu.Target>
                  <Button
                    size="compact-xs"
                    variant="white"
                    style={{ marginLeft: -8 }}
                  >
                    {filterDescription}
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Label>Sort by</Menu.Label>
                  <Menu.Item
                    onClick={() => {
                      const newDirection = filter.sortFields[0] === "name" ? (filter.sortDirections[filter.sortFields[0]] === "ascending" ? "descending" : "ascending") : "descending";
                      onConfirmFilter({
                        ...filter,
                        sortFields: ["name", ...filter.sortFields.filter(f => f != "name")],
                        sortDirections: { ...filter.sortDirections, name: newDirection },
                      });
                    }}
                    leftSection={<IconSortDirection size={16} visibility={filter.sortFields[0] == "name" ? "visible" : "hidden"} />}
                  >
                    Name
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      const newDirection = filter.sortFields[0] === "location" ? (filter.sortDirections[filter.sortFields[0]] === "ascending" ? "descending" : "ascending") : "descending";
                      onConfirmFilter({
                        ...filter,
                        sortFields: ["location", ...filter.sortFields.filter(f => f != "location")],
                        sortDirections: { ...filter.sortDirections, location: newDirection },
                      });
                    }}
                    leftSection={<IconSortDirection size={16} visibility={filter.sortFields[0] == "location" ? "visible" : "hidden"} />}
                  >
                    Location
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      const newDirection = filter.sortFields[0] === "ts" ? (filter.sortDirections[filter.sortFields[0]] === "ascending" ? "descending" : "ascending") : "descending";
                      onConfirmFilter({
                        ...filter,
                        sortFields: ["ts", ...filter.sortFields.filter(f => f != "ts")],
                        sortDirections: { ...filter.sortDirections, ts: newDirection },
                      });
                    }}
                    leftSection={<IconSortDirection size={16} visibility={filter.sortFields[0] == "ts" ? "visible" : "hidden"} />}
                  >
                    Age
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    leftSection={<IconFilter size={16} />}
                    onClick={() => {
                      modals.open({
                        title: "Plants",
                        children: (
                          <PlantFilterDialog
                            initialValues={filter}
                            resetValues={filterReset}
                            onCancel={modals.closeAll}
                            onConfirm={onConfirmFilter}
                          />
                        ),
                      })
                    }}
                  >
                    Custom filter&hellip;
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <Box flex={1} />
            </Group>
          </Stack>

          <Stack mt="md" gap={layout == "cards" ? "md" : "sm"}>
            <PlantList
              layout={layout}
              plants={filteredPlants ? filteredPlants : []}
              onItemClick={(doc) => {
                push(<PlantDetail plant={doc} />, doc.name);
              }}
              onEditClick={writingRoles.includes(currentGarden.role) ? (doc) => openEditPlantModal(convex, doc) : undefined}
            />
          </Stack>
        </Box>
      </FastScrollArea>

      {writingRoles.includes(currentGarden.role) && <FloatingButton
        label="Add plant"
        onClick={() => openAddPlantModal(convex, currentGarden._id)}
      />}
    </Box>
  );
}

// shared between server and client

import { Doc } from "./_generated/dataModel";

export function toUTCDate(date: Date) {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
}

export function utcToday() {
  // returns a date in UTC timezone but where the year/month/day are corresponding to the local ymd
  const now = new Date();
  return toUTCDate(now);
}

export const readingRoles: Doc<"userGardens">["role"][] = [
  "owner",
  "editor",
  "viewer",
];
export const writingRoles: Doc<"userGardens">["role"][] = ["owner", "editor"];

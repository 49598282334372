import { Stack } from "@mantine/core";
import { IconPencil } from "@tabler/icons-react";
import { HydratedEntry } from "../../convex/util";
import { formatDate } from "../lib/util";
import EntryItem from "./EntryItem";
import EntryTimeline from "./EntryTimeline";
import { LayoutType } from "./LayoutChooser";

type EntryListProps = {
  layout: LayoutType;
  entries: HydratedEntry[];
  onItemClick?: (doc: HydratedEntry) => void;
  onEditClick?: (doc: HydratedEntry) => void;
};

export default function EntryList({
  layout,
  entries,
  onItemClick,
  onEditClick,
}: EntryListProps) {
  if (layout === "timeline") {
    return <EntryTimeline entries={entries || []} />;
  }
  return (
    <Stack gap={layout == "cards" ? "md" : "sm"}>
      {entries.map((doc) => (
        <EntryItem
          key={doc._id}
          variant={layout}
          date={formatDate(doc.date)}
          subjects={doc.subjects}
          text={doc.text}
          photos={doc.photos}
          onClick={() => onItemClick && onItemClick(doc)}
          actionIcons={onEditClick ? [
            {
              icon: IconPencil,
              onClick: () => onEditClick(doc),
            },
          ] : []}
        />
      ))}
    </Stack>
  );
}

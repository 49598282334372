import { ActionIcon, Box, MantineSize, Stack, Text } from "@mantine/core";
import { IconMapPinFilled, TablerIcon } from "@tabler/icons-react";
import { HydratedLocation } from "../../convex/util";
import BaseCard from "./BaseCard";
import { LayoutType } from "./LayoutChooser";
import PlantCountText from "./PlantCountText";

import { mantineSizeSmaller } from "../lib/util";
import BaseRow from "./BaseRow";
import FloatingActionIcon from "./FloatingActionIcon";

type LocationItemProps = {
  variant: LayoutType;
  size?: MantineSize;
  doc: Pick<HydratedLocation, "depth" | "name" | "coverPhoto">;
  plantCount?: number;
  sizes?: string;
  onClick?: () => void;
  actionIcons?: { icon: TablerIcon; onClick: () => void }[];
};

export default function LocationItem({
  variant,
  size = "lg",
  doc,
  plantCount,
  sizes,
  onClick,
  actionIcons = [],
}: LocationItemProps) {
  switch (variant) {
    case "list":
      return (
        <Box pos="relative" style={{ marginLeft: 24 * doc.depth }}>
          <BaseRow
            image={doc.coverPhoto?.url}
            placeholder={
              <IconMapPinFilled size={36} color="var(--mantine-color-gray-5)" />
            }
            onClick={onClick}
          >
            <Stack flex={1} gap={0}>
              <Text lineClamp={1}>{doc.name}</Text>
              <PlantCountText count={plantCount} size="xs" />
            </Stack>
            {actionIcons.map(({ icon: Icon, onClick }) => {
              return (
                <ActionIcon
                  key={Icon.displayName}
                  color="gray.7"
                  variant="subtle"
                  style={{ borderRadius: "100px" }}
                  onClick={onClick}
                >
                  <Icon size={18} />
                </ActionIcon>
              );
            })}
          </BaseRow>
        </Box>
      );

    case "cards":
      return (
        <BaseCard
          image={doc.coverPhoto?.url}
          sizes={sizes}
          onClick={onClick}
          placeholder={
            <IconMapPinFilled size="50" color="var(--mantine-color-gray-5)" />
          }
          vignetting="top"
        >
          <Stack pos="absolute" inset={0} p="sm" justify="space-between">
            <Stack gap={0}>
              <Text size={size} fw={700} c="gray.0" lineClamp={1}>
                {doc.name}
              </Text>
              <PlantCountText
                count={plantCount}
                size={mantineSizeSmaller(size, 3)}
                fw={700}
                c="gray.0"
              />
            </Stack>
          </Stack>
          {actionIcons.map(({ icon: Icon, onClick }) => {
            return (
              <FloatingActionIcon
                key={Icon.displayName}
                position="top-right"
                color="gray.0"
                variant="subtle"
                size="md"
                p={3}
                m="sm"
                style={{ borderRadius: "100px", pointerEvents: "auto" }}
                onClick={onClick}
              >
                <Icon />
              </FloatingActionIcon>
            );
          })}
        </BaseCard>
      );
  }
}

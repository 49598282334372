import { useCallback, useState } from "react";
import {
  ActionIcon,
  Button,
  CopyButton,
  Group,
  Stack,
  Switch,
  TextInput,
} from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { useMutation } from "convex/react";
import { api } from "../../convex/_generated/api";
import { HydratedGarden } from "../../convex/util";

interface ShareGardenDialogProps {
  garden: HydratedGarden;
  opened: boolean;
  onClose: () => void;
}

export default function ShareGardenDialog({
  garden,
  onClose,
}: ShareGardenDialogProps) {
  const [gardenToken, setGardenToken] = useState<string | undefined>(garden.token);
  const serverSetGardenToken = useMutation(api.gardens.setGardenToken);
  const setGardenTokenEnabled = useMutation(api.gardens.setGardenTokenEnabled);

  // Local state to handle the switch toggle
  const [isEnabled, setIsEnabled] = useState(garden.tokenEnabled ?? false);

  // Generate a valid linkname from garden name
  const generateLinkname = useCallback((name: string) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9-_]/g, "-")
      .replace(/-+/g, "-") // Replace multiple consecutive hyphens with a single one
      .replace(/^-|-$/g, "") // Remove leading/trailing hyphens
      .substring(0, 20); // Limit to 20 chars
  }, []);

  const handleToggle = async (checked: boolean) => {
    setIsEnabled(checked);

    if (checked) {
      // If turning on and no token exists, create one
      if (!gardenToken) {
        const linkname = generateLinkname(garden.name);
        const token = await serverSetGardenToken({ gardenId: garden._id, linkname });
        setGardenToken(token);
      }
      // Enable sharing
      await setGardenTokenEnabled({ gardenId: garden._id, enabled: true });
    } else {
      // Disable sharing
      await setGardenTokenEnabled({ gardenId: garden._id, enabled: false });
    }
  };

  const shareUrl = gardenToken
    ? `${window.location.origin}/g/${gardenToken}`
    : "";

  return (
    <Stack>
      <Group>
        <Switch
          checked={isEnabled}
          onChange={(event) => handleToggle(event.currentTarget.checked)}
          label="Enable public garden link"
        />
      </Group>

      {isEnabled && (
        <>
          <TextInput
            // label="Anyone with this link can view your garden:"
            value={shareUrl}
            readOnly
            rightSection={
              <CopyButton value={shareUrl}>
                {({ copied, copy }) => (
                  <ActionIcon color="gray" variant="subtle" onClick={copy}>
                    {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
                  </ActionIcon>
                )}
              </CopyButton>
            }
          />
        </>
      )}

      {(isEnabled && gardenToken) ? (
        <Group justify="space-between">
          <Button
            variant="outline"
            color="red"
            onClick={async () => {
              if (gardenToken) {
                // Extract linkname from token
                const linkname = gardenToken.split('/')[0];
                // Generate a new token with the same linkname
                const token = await serverSetGardenToken({ gardenId: garden._id, linkname });
                setGardenToken(token);
              }
            }}
          >
            Reset Link
          </Button>
          <Button onClick={onClose}>Close</Button>
        </Group>
      ) : (
        <Group justify="flex-end">
          <Button onClick={onClose}>Close</Button>
        </Group>
      )}
    </Stack>
  );
} 
import { MantineSize, Pill, Text } from "@mantine/core";
import { IconMapPin, IconSeeding } from "@tabler/icons-react";
import { HydratedEntry } from "../../convex/util";
import DebugTooltip from "./DebugTooltip";
import LocationDetail from "./LocationDetail";
import { usePile } from "./NavPile";
import PlantDetail from "./PlantDetail";

export type SubjectPillsProps = {
  subjects: HydratedEntry["subjects"];
  includePlantLocation?: boolean;
  dimArchivedPlants?: boolean;
  clickable?: boolean;
  size?: MantineSize;
};

export default function SubjectPills({
  subjects,
  includePlantLocation = false,
  dimArchivedPlants = true,
  clickable = false,
  size = "md",
}: SubjectPillsProps) {
  const [push] = usePile(clickable);
  const pills = subjects.map((s) => {
    const onClick = clickable
      ? () => {
        switch (s.type) {
          case "location":
            push(<LocationDetail location={s.doc} />, s.doc.name);
            break;
          case "plant":
            push(<PlantDetail plant={s.doc} />, s.doc.name);
            break;
        }
      }
      : undefined;
    return (
      <DebugTooltip label={`${s.doc._id} ${s.type === "plant" && s.doc.archived ? s.doc.archived : ""}`}>
        <Pill
          key={s.doc._id}
          size={size}
          styles={{
            root: {
              cursor: clickable ? "pointer" : "auto",
            },
            label: { gap: 2, display: "flex", alignItems: "center" },
          }}
          c={dimArchivedPlants && s.type === "plant" && s.doc.archived === "superseded" ? "gray.1" : "gray.9"}
          bg={dimArchivedPlants && s.type === "plant" && s.doc.archived ? "gray.5" : "gray.3"}
          onClick={onClick}
        >
          {s.type == "plant" && <IconSeeding size={14} />}
          {s.type == "location" && <IconMapPin size={14} />}
          <Text size="xs">
            {s.doc.name}
            {s.type == "plant" &&
              includePlantLocation &&
              s.doc.location &&
              ` (${s.doc.location.name})`}
          </Text>
        </Pill>
      </DebugTooltip>
    );
  });
  return <>{pills}</>;
}
